import GenericHead from 'components/GenericHead';
import useTranslate from 'contexts/TranslateContext/useTranslate';
import { Helmet } from 'react-helmet';
import { translateKeywords } from 'utils/metaUtils';

export default function LiveDirectoryHead({
  pagePath,
  market = {},
  country = {},
  genre = {},
}) {
  const translate = useTranslate();

  const { city, stateAbbreviation, marketId } = market || {};
  const { name: countryName, abbreviation: countryAbbreviation } =
    country || {};
  const { name: genreName, id: genreId } = genre || {};
  const countryAbbreviationWithThe =
    countryAbbreviation === 'US' ? 'the US' : countryAbbreviation;
  const countryNameWithThe =
    countryName === 'United States' ? 'The United States' : countryName;
  const cityName = `${city}, ${stateAbbreviation}`;

  let description = translate(
    'Listen to hundreds of the best live radio stations{countryName}, for free! Search for stations near you & around the country.',
    { countryName: countryNameWithThe ? ` across ${countryNameWithThe}` : '' },
  );
  let title = translate(
    'Listen to the Best Live Radio{country} for Free on iHeart.',
    {
      country: countryNameWithThe ? ` in ${countryNameWithThe}` : '',
    },
  );

  if (city && genreName) {
    description = translate(
      'Stream the best live {genreName} radio stations in {city} online for free, only on iHeart!',
      { city: cityName, genreName },
    );
    title = translate('Listen to Top {genreName} Radio Stations in {city}', {
      city: cityName,
      genreName,
    });
  } else if (city) {
    description = translate(
      'Listen to the best live radio stations in {city}. Stream online for free, only on iHeart!',
      { city: cityName },
    );
    title = translate('Listen to Top Radio Stations in {city} for Free', {
      city: cityName,
    });
  } else if (genreName) {
    description = translate(
      'Stream the best live {genreName} radio stations in {countryAbbreviation} for free on iHeart.',
      { countryAbbreviation: countryAbbreviationWithThe, genreName },
    );
    title = translate(
      'Listen to Free, Live {genreName} Radio Stations in {countryAbbreviation} on iHeart',
      { countryAbbreviation: countryAbbreviationWithThe, genreName },
    );
  }

  const keywords = [
    ...(city ? [cityName] : [countryName]),
    ...(genreName ? [genreName] : []),
    translateKeywords(
      translate,
      'Live, Radio, Stations, Internet, Stream, Listen, Music, Free, Online, iHeartRadio, iHeart',
    ),
  ].join(', ');
  const deepLink =
    genreId && !marketId
      ? `goto/genre/${genreId}`
      : `goto/city/${marketId || ''}`;

  return (
    <>
      <GenericHead
        deeplink={deepLink}
        description={description}
        legacyDeeplink={deepLink}
        metaDescription={description}
        ogType="website"
        pagePath={pagePath}
        title={title}
        twitterCard={`ihr://${deepLink}`}
      />
      <Helmet meta={[{ content: keywords, name: 'keywords' }]} />
    </>
  );
}
